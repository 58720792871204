/** @format */

import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { colors } from "../../../Constants/theme";

const columns = [
  {
    field: "srNo",
    headerName: "Sr. No.",
    width: 90,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "id",
  //   headerName: "ID",
  //   flex: 1,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "s_name",
    headerName: "Sponsor name",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "no_of_students",
    headerName: "Number of Students",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "left", // Aligns text to the left
  },
  {
    field: "donation_type",
    headerName: "Donation Frequency",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "since",
    headerName: "Start Date",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "address",
    headerName: "Location",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];

export default function DataTable({ data, length }) {
  // Add serial number to each row
  const dataWithSrNo = data.map((row, index) => ({
    ...row,
    srNo: index + 1,
  }));

  return (
    <Paper
      sx={{
        // height: 400,
        width: "100%",
        bgcolor: colors.tertiary.gradient, // light blue background
        boxShadow: 2,
        borderRadius: 2,
        padding: 2,
      }}
    >
      <DataGrid
        rows={dataWithSrNo}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: length } },
        }}
        pageSizeOptions={[length]}
        sx={{
          border: 0,
          "& .MuiDataGrid-columnHeaders": {
            bgcolor: "red", // Header background color
            fontSize: 16,
            fontWeight: "bold",
            textAlign: "center",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            display: "flex",
            justifyContent: "center", // Center-align header text
            width: "100%",
          },
          "& .MuiDataGrid-cell": {
            bgcolor: "#e3f2fd", // same light blue as Paper background
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      />
    </Paper>
  );
}
