/** @format */
import { height, width } from "@mui/system";
import React from "react";
import ReactPlayer from "react-player";

export default function VideoComponent() {
  return (
    <div
      style={{
        borderRadius: "10px",
        overflow: "hidden",
        width: "100%",
        height: "40vh",
      }}
    >
      <ReactPlayer
        url='https://youtu.be/NV8r58N53hA?si=hjrW8ZcPIYOoxmAW'
        controls
        width='100%'
        height='100%'
      />
    </div>
  );
}
