/** @format */

import React from "react";

function Index() {
  return (
    <div className='text-5xl w-full text-center text-red-400'>Hellow World</div>
  );
}

export default Index;
