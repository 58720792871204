/** @format */

import React, { Fragment, useContext, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { IoCaretDownSharp } from "react-icons/io5";
import { MenuIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import PrimaryButton from "../../Inputs/PrimaryButton/";
import Image from "../../../Constants/Image";
import { colors } from "../../../Constants/theme";
import AuthContext from "../../../context/authContext/AuthContext";

import AvatarDropDown from "../AvatarDropDown/Index";

const navLinks = [
  { label: "Home", href: "/" },
  {
    label: "Accounts",
    href: "",
    subLinks: [
      { label: "Stats & Expenses", href: "/Stats-And-Expense" },
      { label: "Ongoing Campaigns", href: "/Ongoing-Campaigns" },
      {
        label: "Completed Campaigns",
        href: "/Completed-Campaigns",
      },
    ],
  },
  {
    label: "About Us",
    href: "",
    subLinks: [
      { label: "Overview", href: "/Overview" },
      { label: "Sponsors", href: "/Sponsors" },
      { label: "Board of Management", href: "/Board-of-Management" },
      { label: "Departments & Facilities", href: "/Departments&Facilities" },
    ],
  },
  { label: "Contact Us", href: "/Contact-Us" },
];

function NavbarCustom() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const authContext = useContext(AuthContext);
  const { Logout } = authContext;
  const { isLogin } = authContext;

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div
        className='px-8 z-50 w-full fixed top-0 max-w-[1920px] '
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          boxShadow: "0px 7px 7px 0px #00000040",
          backdropFilter: "blur(55px)",
        }}
      >
        <nav className='flex justify-between items-center'>
          <div className='w-[130px] flex justify-start'>
            <Link to={"/"}>
              <img
                src={Image.NavLogo}
                alt='Logo'
                className='w-[9rem] h-[3.8rem] rounded-full'
              />
            </Link>
          </div>
          <div className='flex desktop:hidden'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className='sr-only'>Open main menu</span>
              <MenuIcon className='h-7 w-7 text-black' aria-hidden='true' />
            </button>
          </div>
          <div className='flex w-[601px] justify-end items-center relative max-desktop:hidden'>
            <div className='flex gap-8 items-center'>
              {navLinks.map((link) =>
                link.subLinks ? (
                  <Popover key={link.label} className='relative'>
                    <Popover.Button
                      className={`flex outline-none items-center gap-x-1 text-[0.925rem] font-medium font-[roboto] hover:text-black text-[${colors.text.dark}]`}
                    >
                      {link.label} <IoCaretDownSharp />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-500'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 translate-y-1'
                    >
                      <Popover.Panel className='absolute left-0 top-full z-10 mt-3 w-[170px] max-w-md overflow-hidden rounded bg-white shadow-lg ring-1 ring-gray-900/5'>
                        <div className='pb-4'>
                          {link.subLinks.map((subLink) => (
                            <Link
                              key={subLink.label}
                              to={subLink.href}
                              className={`group relative flex items-center gap-x-6 pl-4 !pt-4 text-[0.8rem] font-[roboto] text-[#333333] hover:bg-gray-50 hover:text-[${colors.primary.dark}]`}
                            >
                              {subLink.label}
                            </Link>
                          ))}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                ) : (
                  <button
                    key={link.label}
                    className={`text-[0.925rem] font-medium font-[roboto] hover:text-black text-[${colors.text.dark}]`}
                  >
                    <Link to={link.href}>{link.label}</Link>
                  </button>
                )
              )}
              {isLogin ? (
                <AvatarDropDown bgcolor={`${colors.primary.light}`} />
              ) : (
                <Link to='/Ongoing-Campaigns'>
                  <PrimaryButton
                    sx={{
                      fontSize: "0.85rem",
                      width: "100%",
                      fontWeight: 700,
                      color: colors.text.main,
                      borderRadius: "5px",
                      padding: "0.3rem 0.7rem",
                    }}
                  >
                    Donate
                  </PrimaryButton>
                </Link>
              )}
            </div>
          </div>
        </nav>
      </div>
      <Dialog
        as='div'
        className='desktop:hidden '
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-10' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <Link to='/' className='-m-1.5 p-1.5 outline-none'>
              <span className='sr-only'>Your Company</span>
              <img className='h-8 w-auto' src={Image.NavLogo} alt='' />
            </Link>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-10 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className=''>
                {navLinks.map((link) =>
                  link.subLinks ? (
                    <Disclosure key={link.label} as='div' className='-mx-3'>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={`flex w-full items-center justify-between font-[roboto] rounded-lg py-2 pl-3 pr-3.5 text-sm font-medium leading-7 text-gray-900 hover:bg-gray-50 hover:text-[${
                              colors.primary.dark
                            }] ${open ? "text-[#CCFBF1]" : ""}`}
                          >
                            {link.label}
                            <IoCaretDownSharp
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-6 w-6 flex-none"
                              )}
                              aria-hidden='true'
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className='mt-2 space-y-2'>
                            {link.subLinks.map((subLink) => (
                              <Link
                                key={subLink.label}
                                to={subLink.href}
                                className={`block rounded-lg py-2 pl-6 pr-3 text-sm font-normal leading-7 text-gray-900 hover:bg-[#d9d9d9] hover:text-[${colors.primary.dark}]`}
                              >
                                {subLink.label}
                              </Link>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={link.label}
                      to={link.href}
                      className={`text-sm font-medium leading-7 text-gray-900 hover:text-[${colors.primary.dark}] block py-2`}
                    >
                      {link.label}
                    </Link>
                  )
                )}

                <div className='flex flex-col  gap-4 py-6'>
                  {isLogin ? (
                    <center>
                      <AvatarDropDown
                        bgcolor={`${colors.primary.light}`}
                        isMobile={true}
                      />
                    </center>
                  ) : (
                    <>
                      <Link to='/Ongoing-Campaigns'>
                        <PrimaryButton
                          sx={{
                            fontSize: "0.85rem",
                            width: "100%",
                            fontWeight: 700,
                            color: colors.text.main,
                            borderRadius: "5px",
                            padding: "0.3rem 0.7rem",
                          }}
                        >
                          Donate
                        </PrimaryButton>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}

export default NavbarCustom;
