/** @format */

import { Grid, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import InputField from "../../../Components/Inputs/InputField";
import RadioGroup from "../../../Components/Inputs/radioGroup";
import PrimaryButton from "../../../Components/Inputs/PrimaryButton";
import SecondaryButton from "../../../Components/Inputs/secondaryButton";
import { colors } from "../../../Constants/theme";
import { Form, Formik } from "formik";
import { useCreateOrUpdate } from "../../../Hooks/useCreateOrUpdate";
import * as yup from "yup";
import { useGetAll } from "../../../Hooks/useGetAll";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert } from "../../../Components/Layout/Alerts/Index";
import SelectField from "../../../Components/Inputs/SelectField/Index";

function EditStats() {
  const [data, setData] = useState(null);
  const [selectedCampaignId, setSelectedCampaignId] = useState("");

  const navigate = useNavigate();
  let { state } = useLocation();
  let { id } = state;
  const addAlert = useAlert();
  const phone = useMediaQuery("(max-width:751px)");
  const validationSchema = yup.object().shape({
    donation_amount: yup.number().required(" is required"),

    donation_date: yup.date().required("is required"),
    // donation_type: yup.object().required("is required"),
  });

  const { donation } = useGetAll({
    key: `/payment/get-donation/${id}/`,
    select: (data) => data?.data,
    onSuccess: (data) => {
      console.log(data, "<----------data");
      setData(data);
    },
  });
  const donationTypeOptions = [
    { label: "Zakat", value: "Zakat" },
    { label: "Fitra", value: "Fitra" },
    { label: "Interest Offloading", value: "Interest Offloading" },
    { label: "Hadiya", value: "Hadiya" },
    { label: "Sadaqah", value: "Sadaqah" },
    { label: "Lillah", value: "Lillah" },
    { label: "general donation", value: "general donation" },
  ];

  const initialValues = {
    donor_name: data?.donor_name || "",
    donor_email: data?.donor_email || "",
    donation_amount: data?.donation_amount || "",
    donation_type:
      donationTypeOptions.find(
        (option) => option.value === data?.donation_type
      ) || "", // donation_date: data?.donation_date ? data.donation_date.slice(0, 10) : "", // Format date
    campaign: data?.campaign.campaign_name || "",
    isPaid: data?.isPaid || true,
  };
  const { data: campaignData, refetch } = useGetAll({
    key: `/campaign/nt/`,
    select: (data) => data?.data,
    onSuccess: (data) => {},
  });
  const campaignOptions = campaignData?.rows.map((campaign) => ({
    label: campaign.campaign_name,
    value: campaign.campaign_id,
  }));
  // const selectedCampaign = campaignData?.rows?.find(
  //   (campaign) => campaign.campaign_id === selectedCampaignId
  // );

  const { mutate, isLoading } = useCreateOrUpdate({
    url: `/payment/get-donation/${id}/`,
    method: "put",
    onSuccess: (response) => {
      addAlert(
        "Update Donation successfully!",
        "success",
        {
          vertical: "top",
          horizontal: "center",
        },
        3000
      );
      navigate(-1);
    },
    onError: (error) => {
      addAlert(
        `${error}error`,
        "error",
        {
          vertical: "top",
          horizontal: "center",
        },
        3000
      );
    },
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={(values) => {
        const formData = new FormData();
        formData.append("donor_name", values.donor_name);
        formData.append("donor_email", values.donor_email);
        formData.append("donation_amount", values.donation_amount);
        formData.append(
          "donation_type",
          values.donation_type.value || data?.donation_type
        );

        formData.append(
          "campaign",
          values.campaign.value || data?.campaign.campaign_id
        );
        formData.append("isPaid", values.isPaid);

        mutate(formData);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Grid container spacing={{ xs: 2, lg: 4 }}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='donor_name'
                label='Donor Name'
                placeholder='Enter donor name'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='donor_email'
                type={"email"}
                label='Donor Email'
                placeholder='Enter email'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='donation_amount'
                type={"number"}
                label='Donation Amount'
                placeholder='Enter amount'
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField
                name='campaign'
                options={campaignOptions}
                // onChange={(e) => setSelectedCampaignId(e.target?.value)} // Set selected campaign ID
                label='Campaign'
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root ": {
                    height: phone ? "45px" : "",
                  },
                }}
                placeholder='Select Campaign'
                value={values.campaign}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} lg={4}>
              <InputField
                name='donation_date'
                label='Donation Date'
                type={"date"}
                placeholder='Enter date'
              />
            </Grid> */}
            <Grid item xs={12} sm={6} lg={4}>
              <SelectField
                name='donation_type'
                options={donationTypeOptions}
                label='Donation Type'
                sx={{
                  "& .MuiInputBase-root.MuiOutlinedInput-root ": {
                    height: phone ? "45px" : "",
                  },
                }}
                placeholder='Select donation type'
                value={values.donation_type}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} lg={4}>
              <RadioGroup
                name={"isPaid"}
                onChange={(e) => {
                  setFieldValue("isPaid", e.target.value);
                }}
                options={[
                  { label: "Paid", value: true },
                  { label: "Pending", value: false },
                ]}
                label={"Status"}
                value={values.isPaid}
              />{" "}
            </Grid> */}
            <Grid
              item
              mt={2}
              xs={12}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={4}
            >
              <SecondaryButton
                onClick={() => {
                  navigate(-1);
                }}
                className='w-[69px] h-[32px] border border-[#082F49]'
                sx={{
                  height: "44px",
                  width: "100px",
                  border: `1px solid ${colors.primary.dark}`,
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                type='submit'
                isLoading={isLoading}
                sx={{
                  height: "44px",
                  width: "100px",
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                Save
              </PrimaryButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default EditStats;
